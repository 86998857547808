import { Component, Vue } from "vue-property-decorator";
import UlContentHeader from "@/components/UlContentHeader.vue";
import UlBreadcrumbs from "@/components/UlBreadcrumbs.vue";
import UIDataTable from "@/components/UIDataTable.vue";
import { DEFAULT_TABLE_OPTIONS, TableOptions } from "@/api/request";
import { SearchRequest } from "@/api/category-shop/request";
import CategoryShopSearch, { ListItem } from "@/store/category-shop/search";
import MainShopGet from "@/store/main-shop/get";
import Flash, { ErrorAlert } from "@/store/common/flash";

@Component({ components: { UlContentHeader, UlBreadcrumbs, UIDataTable } })
export default class Index extends Vue {
  // ------------
  // 固定値
  // ------------
  // タイトル
  headingMain = "支店カテゴリー一覧";
  headingSub = "Branch store category setting";
  breadCrumbs = [
    { text: "マスターデータ管理", disabled: true },
    { text: "支店カテゴリー", disabled: true },
    { text: "店舗一覧", disabled: true }
  ];
  // テーブルヘッダ（UIDataTableコンポーネントに渡す）
  tableHeaders = [
    { text: "店舗ID", value: "id" },
    { text: "店舗名", value: "name" },
    { text: "", value: "space", sortable: false },
    {
      label: "修正",
      text: "",
      value: "edit",
      sortable: false
    }
  ];

  // 公開/非公開 TODO: valueが未定
  statusItems = [
    { text: "非公開", value: 0 },
    { text: "公開", value: 1 }
  ];

  menuProps = {
    closeOnClick: false,
    closeOnContentClick: false,
    disableKeys: true,
    openOnClick: false,
    maxHeight: 304
  };

  // ------------
  // 変動値
  // ------------
  // ローディングステータス
  isLoading = false;

  // 検索入力オプション
  inputOptions = {
    searchShopId: null as null | number,
    selectShopIds: null as null | number[],
    selectViewFlg: null as null | number
  } as SearchRequest;

  // テーブル検索オプション（UIDataTableコンポーネントに渡す）
  tableOptions = DEFAULT_TABLE_OPTIONS;

  // ------------

  /**
   * テーブルに表示するアイテムリスト（UIDataTableコンポーネントに渡す）
   * TODO: store未実装
   */
  get tableItems() {
    return CategoryShopSearch.getItems;
  }

  /**
   * 総件数（UIDataTableコンポーネントに渡す）
   */
  get totalCount() {
    return CategoryShopSearch.getTotalCount;
  }

  get shopItems() {
    return MainShopGet.getItems;
  }

  /**
   * createdライフサイクルフック（UIDataTableコンポーネントに渡す）
   * TODO: store未実装
   */
  async created() {
    await CategoryShopSearch.restore(this.inputOptions as SearchRequest);
    const request = CategoryShopSearch.getSearchRequest;

    // // 検索入力オプション
    request.searchShopId &&
      (this.inputOptions.searchShopId = request.searchShopId);
    request.selectShopIds &&
      (this.inputOptions.selectShopIds = request.selectShopIds);
    request.selectViewFlg &&
      (this.inputOptions.selectViewFlg = request.selectViewFlg);

    // 検索テーブルオプション
    request.page && (this.tableOptions = request);
    await this.search();
  }

  /**
   * beforeDestroyライフサイクルフック
   */
  async beforeDestroy() {
    await MainShopGet.clearResponse();
    await CategoryShopSearch.clearResponse();
  }

  /**
   * ページング変更コールバック
   *
   * @param tableOptions TableOptions
   */
  async tableChangeCallback(tableOptions: TableOptions) {
    // 子コンポーネントへpagingOptionsの変更が通知される
    this.tableOptions = tableOptions;
    await this.search();
  }

  /**
   * 検索ボタンがクリックされた際のコールバック
   */
  async searchClickCallback() {
    await Flash.clear();
    await this.search();
  }

  /**
   * テーブル行の編集ボタンがクリックされた際のコールバック
   * TODO: ITEM未実装
   *
   * @param item 選択行のAdminItem
   */
  async editClickCallback(item: ListItem) {
    await this.$router.push({
      name: "category-edit",
      params: { id: item.id.toString() },
      query: { shopName: item.name }
    });
  }

  /**
   * 本店店舗情報の一覧を取得する処理
   */
  async fetchMainShopList(): Promise<boolean> {
    if (MainShopGet.isSuccess) {
      return true;
    }

    await MainShopGet.get();
    if (!MainShopGet.isSuccess) {
      await Flash.setErrorNow({
        message: MainShopGet.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
    return MainShopGet.isSuccess;
  }

  /**
   * 検索処理
   * TODO: store未実装
   */
  async search() {
    this.isLoading = true;
    let isSuccess = await this.fetchMainShopList();
    if (!isSuccess) {
      this.isLoading = false;
      return;
    }

    let request = this.createRequest();
    await CategoryShopSearch.search(request);
    if (!CategoryShopSearch.isSuccess) {
      await Flash.setErrorNow({
        message: CategoryShopSearch.getMessage,
        showReloadButton: true
      } as ErrorAlert);
    }
    this.isLoading = false;
  }

  /**
   * dataから検索用のリクエストを作成する
   */
  private createRequest(): SearchRequest {
    const request = this.tableOptions as SearchRequest;
    if (this.inputOptions.searchShopId) {
      request.searchShopId = this.inputOptions.searchShopId;
    } else {
      delete request.searchShopId;
    }

    if (this.inputOptions.selectShopIds) {
      request.selectShopIds = this.inputOptions.selectShopIds;
    } else {
      delete request.selectShopIds;
    }

    if (this.inputOptions.selectViewFlg == undefined) {
      delete request.selectViewFlg;
    } else {
      request.selectViewFlg = this.inputOptions.selectViewFlg;
    }

    return request;
  }
}
