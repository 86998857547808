import service from "@/api/service";
import { SearchRequest } from "@/api/category-shop/request";
import { SearchResponse } from "@/api/category-shop/response";

/**
 * カテゴリー情報店舗一覧検索APIをコールします。
 *
 * @param searchRequest 検索のリクエストボディ
 * @return SearchResponse
 */
export async function search(searchRequest: SearchRequest) {
  const response = await service.post("/search-category-shop", searchRequest);
  return response.data as SearchResponse;
}
